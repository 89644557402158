.pageContainer {
  overflow: auto;
  z-index: 1;
}

.finger {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 25px;
}

.imgContainer {
  width: 100%;
  height: 100%;
  position: relative;
}

.imgContainer .lobbyBg {
  max-width: none;
  overflow: auto;
}
