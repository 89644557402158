.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 32;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.mainContainer {
  position: relative;
  bottom: -90%;
  width: 100%;
  height: 80%;
  background: #fff;
  box-shadow: 8px 8px 15px 0px #0000001A;
  border-radius: 10px;
  margin-bottom: 20px;
  transition: bottom 0.8s cubic-bezier(0.860, 0.000, 0.070, 1.000);
}

.upperContent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 200px;
  background: #000;
  color: #fff;
  border-radius: 10px 10px 0 0;
}

.upperContentText {
  margin-top: 48px;
  text-align: center;
  font-weight: 400;
  font-size: 32px;
  line-height: 44.8px;
}

.closeBtn {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.closeBtn:hover {
  opacity: 0.7;
}

.salesChooseContainer {
  position: absolute;
  top: 120px;
  left: 50%;
  transform: translateX(-50%);

  width: 90%;
  max-width: 460px;
  height: 30%;
  min-height: 300px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 8px 8px 15px 0px #0000001A;
  z-index: 10;
  
}

.outerNoShow {
  display: none;
}

.innerShow {
  bottom: 0px;
}

.titleContainer {
  display: flex;
  align-items: center;
  padding: 20px 20px 0 ;
}

.circle {
  flex: 0 0 50px;
  position: relative;
  width: 50px;
  height: 50px;
  background: #000;
  border-radius: 50%;
  margin-right: 10px;
}

.startIcon {
  position: absolute;
  top: 50%;
  left: 55%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.title {
  font-weight: 600;
  font-size: 18px;
  line-height: 25.2px;
  padding-bottom: 6px;
}

.titleDescription {
  font-weight: 400;
  font-size: 12px;
  line-height: 16.8px;
}

.salesOuterContainer {
  height: calc(100% - 90px);
  padding: 0 0 20px 0;
  overflow-y: auto;
}

.salesContainer {
  margin: 0 80px;
  display: flex;
  padding: 17px 0;
  border-bottom: 1px solid #D4D4D4;
  cursor: pointer;
}

.avatarContainer {
  position: relative;
}

.avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.status {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 9px;
  width: 9px;
  border-radius: 50%;
  background: #A5FF8F;
}

.nameContainer {
  margin-left: 10px;
  display: flex;
  align-items: center;
}

.name {
  font-weight: 600;
  font-size: 13px;
  /* line-height: 18.2px; */
}

.description {
  font-weight: 400;
  font-size: 11px;
  line-height: 15.4px;
}

@media screen and (min-width: 768px) {
  .mainContainer {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 500px;
    height: min(750px, 90%);
    opacity: 1;
    transition: bottom 1.2s cubic-bezier(0.860, 0.000, 0.070, 1.000);
    z-index: 11;
  }

  .salesChooseContainer {
    position: absolute;
    top: 120px;
    width: 460px;
    left: 50%;
    transform: translateX(-50%);
    padding: 0 80px 0 30px;
    height: 350px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 8px 8px 15px 0px #0000001A;
  }
  
  .show {
    display: block;
  }
  
  .noShow {
    /* bottom: 165px */
    bottom: -100%;
  }
  
  .titleContainer {
    display: flex;
    align-items: center;
    padding: 20px 20px 0 0;
  }

  .circle {
    flex: 0 0 70px;
    position: relative;
    width: 70px;
    height: 70px;
    background: #000;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .startIcon {
    position: absolute;
    top: 50%;
    left: 55%;
    transform: translate(-50%, -50%);
    width: 35px;
    height: 35px;
    object-fit: contain;
  }
  
  .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 25.2px;
    padding-bottom: 6px;
  }
  
  .titleDescription {
    font-weight: 400;
    font-size: 12px;
    line-height: 16.8px;
  }
  
  .salesOuterContainer {
    height: calc(100% - 90px);
    padding: 0 0 20px 0;
    overflow-y: auto;
  }
  
  .salesContainer {
    margin: 0 20px 0 80px;
    display: flex;
    padding: 17px 0;
    border-bottom: 1px solid #D4D4D4;
    cursor: pointer;
  }
  
  .avatarContainer {
    position: relative;
  }
  
  .avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  
  .status {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 9px;
    width: 9px;
    border-radius: 50%;
    background: #A5FF8F;
  }
  
  
  .name {
    font-weight: 600;
    font-size: 13px;
    line-height: 18.2px;
  }
  
  .description {
    font-weight: 400;
    font-size: 11px;
    line-height: 15.4px;
  }
}