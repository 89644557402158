.shopping_card {
  border-radius: 15px;
  border: none;
  min-height: 330px;
}

.card_img {
  object-fit: cover;
  height: 170px;
  width: 100%;
  position: relative;
}

.card_cover {
  position: relative;
  padding: 5px;
}

.card_tag {
  position: absolute;
  top: 18px;
  left: 9px;
  background-color: #282828;
  border-radius: 15px;
  width: 80px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tag_text {
  color: white;
  font-size: 15px;
  font-weight: bold;
  line-height: 30px;
  margin-left: 3px;
}

.time {
  display: flex;
  align-items: center;
  font-size: 9px;
  color: #797979;
}

.time_text {
  margin-left: 3px;
}

.card_title_layout {
  display: flex;
  justify-content: space-between;
}

.card_title,
.price {
  color: #282828;
  font-size: 14px;
  line-height: 22px;
  font-weight: bolder;
}

.detail_container {
  margin-bottom: 19px;
}

.household, .oid {
  line-height: 22px;
  font-size: 14px;
  letter-spacing: 2px;
  word-break: break-word;
}

.download {
  display: flex;
  align-items: center;
}

.download_text {
  color: #282828;
  font-size: 14px;
  font-weight: bolder;
  margin: 0 8px 0 4px;

}

.delete {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
}

.delete::before,
.delete::after {
    position: absolute;
    top: 9px;
    left: 3.5px;
    content: '';
    width: 13px;
    height: 2px;
    background-color: black;
}

.delete::before {
  transform: rotate(45deg);
}

.delete::after {
  transform: rotate(-45deg);
}

.disable {
  opacity: 0.7;
}
