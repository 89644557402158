.select {
  box-sizing: border-box;
  appearance: none;
  padding-left: 17px;
  padding-right: 9px;
  padding-top: 4px;
  padding-bottom: 4px;
  position: relative;
  background-image: url(../../../assets/selectbox_arrow.png);
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 50%;
  border: 1px solid#CCCCCC;
  height: 30px;
  flex: 1;
  color: #9c9c9c;
  width: 100%;
  font-size: 12px;
  line-height: 22px;
}

.select:focus {
  outline: none;
}
