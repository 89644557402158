.phone {
  margin-bottom: 53px;
}

.signup {
  text-align: center;
  color: #666666;
  font-size: 13px;
  line-height: 30px;
}

.otp_layout {
  margin-bottom: 53px;
}

.otp_content {
  display: flex;
}

.otp_input {
  flex: 1;
}

.otp_resend {
  flex-basis: 100px;
  height: 30px;
  margin-left: 10px;
}

.loginButton {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  background-color: #282828;
  border-radius: 0;
  border: none;
  flex-basis: 300px;
  height: 50px;
  color: white;
  cursor: pointer;
}

.loginButton:disabled {
  background-color: #cccccc;
  color: #ffffff;
  cursor: not-allowed;
}

.loginButton:hover, .loginButton:focus {
  opacity: 0.7;
  background: #000;
  color: #ffffff;
}

