.layout {
  border-bottom: 1px solid #cccccc;
  padding-bottom: 6px;
  display: flex;
  align-items: center;
}

.prefix {
  font-size: 12px;
  line-height: 22px;
  font-weight: 600;
  color: #282828;
  margin-right: 14px;
}

.input {
  outline: none;
  border: none;
  flex-grow: 1;
  width: 100%;
  font-size: 12px;
  line-height: 16px;
}

.input::placeholder {
  color: #9c9c9c;
}

.input:disabled {
  background: white;
  color: #9c9c9c;
}
