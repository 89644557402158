.otp_layout {
  margin-bottom: 53px;
}

.otp_content {
  display: flex;
}

.otp_input {
  flex: 1;
}

.otp_resend {
  flex-basis: 100px;
  height: 30px;
  margin-left: 10px;
  background-color: #416EDE;
}

.login_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  background-color: #282828;
  border-radius: 0;
  border: none;
  flex-basis: 300px;
  height: 50px;
  color: white;
  cursor: pointer;
}

.login_btn:disabled {
  background-color: #cccccc;
  color: #ffffff;
  cursor: not-allowed;
}

.login_btn:hover, .login_btn:focus {
  opacity: 0.7;
  background: #000;
  color: #ffffff;
}

.form_title {
  font-size: 13px;
  line-height: 30px;
  color: #666666;
  margin-bottom: 6px;
}

.seconds {
  color: #ec455a
  ;
}

.error_bottom {
  border-bottom: 1px solid #ee5c6e;
}

.submit {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}
