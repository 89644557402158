.layout {
  display: flex;
  height: 100%;
}

.left {
  min-width: 0px;
  flex: 1;
}

.right{
  flex-basis: 520px;
  box-sizing: border-box;
  padding: 50px 20px 45px 20px;
  overflow-y: auto;
}

.is_order {
  padding: 50px 0 45px;
}

@media (max-width: 768px) {
  .layout {
    display: block;
    height: initial;
    padding-top: 40px;
  }

  .left {
    height: 400px;
  }
  .right {
    padding-top: 23px;
    padding-left: 10px;
    padding-right: 10px;
    overflow: initial;
  }

  .is_order {
    padding: 0 0 45px;
  }
}