.header {
  grid-column: 1 / 5;
  font-weight: bold;
  margin-top: 28px;
}

.wrapper {
  display: grid;
  justify-content: center;
  grid-template-columns: 280px 280px 280px 280px;
  grid-gap: 25px;
  margin-bottom: 20px;
  padding-bottom: 50px;
}

@media screen and (max-width: 1024px) {
  .header {
    grid-column: 1 / 4;
  }
  .wrapper {
    grid-template-columns: 1fr 1fr 1fr;
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media screen and (max-width: 475px) {
  .header {
    grid-column: 1/3;
    margin-left: 10px;
  }

  .wrapper {
    grid-template-columns: 1fr 1fr;
    padding-left: 0;
    padding-right: 0;
    grid-gap:8px;
  }
}
