.modal {
  position: relative;
  z-index: 11;
}

.outerContainer {
  position: fixed;
  bottom: 85px;
  left: 0%;
  width: 65px;
  height: 80px;
  z-index: 9;
  background: #fff;
  border-radius: 0 20px 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}


.csBtnContainer {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chatting {
  width: 51px;
  height: 52px;
  transform: translateX(3px);
  object-fit: contain;
}

.chattingGif {
  width: 65px;
  height: 80px;
  transform: translateX(0);
  object-fit: cover;
}