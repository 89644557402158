.checkbox {
  box-sizing: border-box;
  appearance: none;
  background: white;
  outline: none;
  width: 20px;
  height: 20px;
  border: 1px solid #cccccc;
  vertical-align: text-bottom;
  margin-right: 10px;
}

.checkbox:checked {
  background: #282828;
  border: 1px solid #282828;
}

.label {
  font-size: 13px;
  line-height: 30px;
}
