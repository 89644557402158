.sectionContainer {
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr max-content 3fr;
}

.contentContainer {
  grid-area: 2/1/3/2;
}

.titleContainer {
  margin: 0 0 10px 17px;
}

.titleEg {
  font-size: 60px;
  font-weight: 100;
  line-height: 84px;
  letter-spacing: 2.5px;
}

.titleCt {
  font-size: 18px;
  font-weight: 400;
  line-height: 25.2px;
  letter-spacing: 1px;
  border-bottom: 1px solid #000000;
}

.carouselContainer {
  position: relative;
  width: 100%;
  height: calc(100% - 94px);
}

.carouselContainer .bg {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 80%;
  height: 100%;
}

.nextBtn {
  position: absolute;
  top: 50%;
  right: 3%;
  transform: translateY(-50%);
  width: 25.36px;
  height: 25.36px;
  background-image: url('/src/assets/fill_next.png');
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 1;
  cursor: pointer;
}

.nextBtn:hover {
  opacity: 0.7;
}

.preBtn {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 25.36px;
  height: 25.36px;
  background-image: url('/src/assets/fill_prev.png');
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 1;
  cursor: pointer;
}

.preBtn:hover {
  opacity: 0.7;
}

.blueBg {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 40%;
  background: #EEFCFF;
  z-index: -1;
}