.layout {
  flex-basis: 450px;
}

.name_item {
  flex-basis: 180px;
}

.year {
  flex-basis: 130px;
  display: flex;
}

.month {
  flex-basis: 100px;
  display: flex;
}

.day {
  flex-basis: 100px;
  display: flex;
}

.city {
  flex-basis: 130px;
  display: flex;
  flex-grow: 0;
}

.district {
  flex-basis: 130px;
  display: flex;
  flex-grow: 0;
}

.postal_code {
  flex-basis: 100px;
  flex-grow: 0;
}

.contract_address {
  margin-bottom: 18px;
}


.address {
  margin-bottom: 16px;
}

.phone_layout {
  display: flex;
  justify-content: space-between;
  background-color: #f5f5f5;
  box-sizing: border-box;
  padding: 15px;
  height: 50px;
}

.mb_10 {
  margin-bottom: 10px;
}

.mb_40 {
  margin-bottom: 40px;
}

.arrow_img {
  width: 50px;
  position: absolute;
  right: 40px;
}

.qrcode_btn {
  background-color: transparent;
  color: #282828;
  border: 1px solid #c4c4c4;
  position: relative;
  flex-basis: 450px;
}

.link_btn {
  background-color: #416EDE;
  position: relative;
  flex-basis: 450px;
}

.form_input {
  padding-left: 15px;
}

.form_select:disabled {
  border: 1px solid transparent;
  border-bottom: 1px solid #cccccc;
  background: initial;
  padding-left: 15px;
  text-overflow: ellipsis;

}

@media (max-width: 768px) {
  .phone_layout {
    flex-wrap: wrap;
    justify-content: center;
    height: inherit;
  }

  .telephone1 {
    margin-bottom: 15px;
  }

  .qrcode_btn {
    flex-basis: auto;
    width: 100%;
  }

  .link_btn {
    flex-basis: auto;
    width: 100%;
  }
}