.name_spacer {
  flex: 1 1 10px;
}

.name_item {
  flex: 1 1 180px;
}

.job {
  border-width: 0 0 1px 0 ;
  padding: 2px;
  border-radius: 0;
}

.day_spacer {
  flex: 1 2 20px;
}

.year {
  flex: 1 1 130px;
  display: flex;
}

.month {
  flex: 1 1 100px;
  display: flex;
}

.day {
  flex: 1 1 100px;
  display: flex;
}

.add_spacer {
  flex: 1 2 20px;
}

.city {
  flex: 1 1 130px;
  display: flex;
}

.district {
  flex: 1 1 100px;
  display: flex;
}

.contract_address {
  margin-bottom: 18px;
}

.postal_code {
  flex: 1 1 100px;
  align-self: flex-end;
}

.address {
  margin-bottom: 9px;
}

.privacy_layout {
  margin-bottom: 20px;
}

.privacy {
  display: flex;
  align-items: center;
}

.privacy_label {
  font-size: 13px;
  line-height: 30px;
  color: #666666;
}

.privacy_popup {
  text-decoration: underline;
  font-weight: 700;
  cursor: pointer;
}

.login {
  text-align: center;
  color: #666666;
  font-size: 13px;
  line-height: 30px;
  margin-bottom: 20px;
}

.email {
  margin-bottom: 21px;
}

.sign_up_button {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  background-color: #282828;
  border-radius: 0;
  border: none;
  flex-basis: 300px;
  height: 50px;
  color: white;
  cursor: pointer;
}

.sign_up_button:disabled {
  background-color: #cccccc;
  color: #ffffff;
  cursor: not-allowed;
}

.sign_up_button:hover, .sign_up_button:focus {
  opacity: 0.7;
  background: #000;
  color: #ffffff;
}

.checkbox_error {
  border: 1px solid #EC455A;
}

.signup_rule_layout {
  display: flex;
  margin-top: 12px;
}

.signup_checkbox {
  display: none;
}

.signup_label {
  font-size: 13px;
  line-height: 25px;
  color: #666666;
}

.signup_popup {
  font-size: 13px;
  line-height: 25px;
  color: #666666;
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
}
