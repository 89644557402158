.btnContainer {
  position: absolute;
  bottom: 5%;
  right: 5px;
  z-index: 1;
}

.line {
  width: 30px;
  height: 30px;
  margin-bottom: 18px;
  background-image: url('../../assets/lineShare.svg');
  background-size: contain;
  border-radius: 50%;
  cursor: pointer;
}

.line:hover {
  opacity: 0.7;
}

.fb {
  width: 30px;
  height: 30px;
  margin-bottom: 18px;
  background-image: url('../../assets/fbShare.svg');
  background-size: contain;
  border-radius: 50%;
  cursor: pointer;
}

.fb:hover {
  opacity: 0.7;
}

.copyLink {
  position: relative;
  width: 30px;
  height: 30px;
  margin-bottom: 18px;
  background-image: url('../../assets/copyLink.svg');
  background-size: contain;
  border-radius: 50%;
  cursor: pointer;
}

.copyLink:hover {
  opacity: 0.7;
}

@media screen and (min-width: 768px) {
  .btnContainer {
    position: absolute;
    top: 80%;
    right: 22px;
  }
}