.layout {
  border-bottom: 1px solid #cccccc;
  display: flex;
}

.input {
  outline: none;
  border: none;
  width: 100%;
  font-size: 12px;
  line-height: 22px;
  padding: 0px;
  margin-bottom: 3px;
}

.input::placeholder {
  color: #9c9c9c;
}

.input:disabled {
  background: white;
  color: #9c9c9c;
}
