.layout {
  background-color:#fafafa;
  padding-top: 50px;
  min-height: 100%;
  height: unset !important; /*overwrite page className*/
}

.header {
  display: flex;
  align-items: center;
  grid-column: 1 / 3;
  grid-row: 1 / 2;
  margin-top: 20px;
}

.header_bold {
  font-size: 32px;
  font-weight: bold;
}

.header_username {
  font-size: 32px;
  color: #9c9c9c;
  max-width: 70%;
  overflow: hidden;
  white-space: nowrap;
  padding-bottom: 5px;
  text-overflow: ellipsis;
}

.user_layout {
  display: grid;
  justify-content: center;
  grid-template-columns: 720px 260px;
  grid-gap: 20px;
}

.user {
  flex-basis: 720px;
}

.advertisement {
}

.privacy {
  text-decoration: underline;
  margin-left: 10px;
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  .user_layout {
    grid-template-columns: 720px 1fr;
  }
}

@media screen and (max-width: 768px) {
  .layout {
    padding-top: 40px;
  }

  .header {
    display: none;
  }

  .user_layout {
    grid-template-columns: initial;
    margin-top: 6px;
  }

  .advertisement {
    display: none;
  }

}