.navBar {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  top: 0;
  left: 0;
  height: 40px;
  width: 100%;
  z-index: 10;
  background: #F7F7F5;
}

.noShow {
  display: none;
}

.preBtn {
  width: 12px;
  height: 12px;
  object-fit: contain;
  object-position: center;
}

.logoImg {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 75px;
  height: 100%;
  transform: translate(-50%, -50%);
  object-fit: contain;
  object-position: center;
  cursor: pointer;
}

.rightContent {
  display: flex;
  align-items: center;
}

.serviceContainer {
  position: relative;
  height: 25px;
  width: 25px;
  object-fit: contain;
  object-position: center;
  cursor: pointer;
}

.notification {
  display: none;
  position: absolute;
  width: 8px;
  height: 8px;
  background: red;
  border-radius: 50%;
}

.userNotification {
  top: 0;
  right: 0;
  transform: translate(-50%, -50%);
}

.salesNotification {
  top: 0;
  right: 0;
}

.show {
  display: block;
}

.toCenterContainer {
  display: flex;
  align-items: center;
}

.memberImg {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  margin-left: 20px;
  padding: 2px;
  object-fit: contain;
  object-position: center;
  cursor: pointer;
  border: 1px solid #D7E6F9;
  background-color: #f5f9fd;
}

.name {
  color: #000;
  font-weight: 600;
  font-size: 11px;
  letter-spacing: 1px;
  padding-left: 5px;
}

@media screen and (min-width: 768px) {
  .navBar {
    height: 48px;
    padding: 0 80px;
    background: transparent;
  }

  .logoImg {
    position: relative;
    top: 0;
    left: 0;
    transform: translate(0, 0);
    height: 35px;
    width: auto;
    object-fit: contain;
    object-position: center;
    cursor: pointer;
  }

  .memberImg {
    height: 35px;
    width: 35px;
    margin-left: 55px;
  }

  .salesNotification {
    top: 0;
    right: 0;
  }
}