.outerContainer {
  position: absolute;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.outerContainer:hover {
  opacity: 0.7;
}

.icon {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  
  background: #000;
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;
}

.icon::after {
  content: "";

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  
  height: 48px;
  width: 48px;
  border-radius: 50%;
  background-color: transparent;
  
  border: 1px solid #fff;
}

.iconPic {
  width: 50%;
  height: 50%;
  object-fit: contain;

}

.iconText {
  min-width: 115px;
  padding: 5px 24px 5px 30px;

  background: #fff;
  border-radius: 30px;

  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 1px;
  white-space: nowrap;
  color: #282828;
  text-align: center;
}
