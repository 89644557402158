.react_modal_content {
  border-radius: 25px;
  max-width: 500px;
  height: 500px;
  padding: 40px;
  margin: auto;
  position: absolute;
  inset: 40px;
  background-color: rgb(255, 255, 255);
  outline: none;
  box-shadow: 8px 8px 15px 0px #0000001A;
}

.react_modal_overlay {
  position: fixed;
  inset: 0px;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 100;
}

.title {
  text-align: center;
  font-size: 30px;
  color: #333333;
  margin-bottom: 60px;
}

.content {
  display: grid;
  grid-template-columns: 100px 100px 100px 100px;
  grid-gap: 10px;
  justify-content: center;
}

.close {
  width: 50px;
  height: 50px;
  position: absolute;
  bottom: -50px;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
}

.close::before,
.close::after {
    position: absolute;
    top: 50%;
    content: '';
    width: 100%;
    height: 1px;
    background-color: black;
}

.close::before {
  transform: rotate(45deg);
}

.close::after {
  transform: rotate(-45deg);
}

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px transparent solid;
  box-sizing: border-box;
}

@media screen and (max-width: 768px) {
  .react_modal_content {
    height: 550px;
  }

  .title {
    margin-bottom: 30px;
  }

  .content {
    grid-template-columns: 100px 100px;
  }
}