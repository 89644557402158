.slide_pre {
  position: absolute;
  top: 50%;
  left: 10px;
  right: auto;
  z-index: 10;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../../../../assets//fill_prev_white.png');
  cursor: pointer;
}

.slide_next {
  position: absolute;
  top: 50%;
  right: 10px;
  left: auto;
  z-index: 10;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../../../../assets//fill_next_white.png');
  cursor: pointer;
}