.layout {
  max-width: 500px;
}

.edit_layout {
  display: flex;
  justify-content: flex-end;
}

.edit_img {
  margin-right: 5px;
}

.edit_btn {
  display: flex;
  align-items: center;
  color: #4a4a4a;
  font-size: 18px;
  outline: none;
  background-color: transparent;
  border:none;
  padding: 0;
  cursor: pointer;
}

.phone {
  font-size: 13px;
  line-height: 18px;
  margin-left: 15px;
}

.row {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.form_title {
  color: #666666;
  font-size: 13px;
  line-height: 30px;
  flex-basis: 100px;
  flex-shrink: 0;
  text-align: right;
  margin-right: 4px;
}

.form_input {
  padding-left: 15px;
}

.form_select:disabled {
  border: 1px solid transparent;
  border-bottom: 1px solid #cccccc;
  background: initial;
  padding-left: 15px;
  text-overflow: ellipsis;

}

.email {
  flex: 1;
  max-width: 250px;
}

.lastName {
  flex: 1;
  max-width: 110px;
  margin-right: 28px;
}

.firstName {
  flex: 1;
  max-width: 110px;
}

.male {
  margin-left: 15px;
  margin-right: 20px;
}

.female {
  margin-right: 16px;
}

.job {
  flex: 1;
  max-width: 110px;
}

.year {
  flex: 1;
  max-width: 80px;
  margin-right: 5px;
}

.month {
  flex: 1;
  max-width: 80px;
  margin-right: 5px;
}

.day {
  flex: 1;
  max-width: 80px;
  margin-right: 5px;
}

.city {
  flex: 1;
  max-width: 80px;
  margin-right: 5px;
}

.region {
  flex: 1;
  max-width: 80px;
  margin-right: 5px;
}

.postalCode {
  flex: 1;
  max-width: 80px;
  align-self: flex-end;
}

.address {
  flex: 1;
  max-width: 250px;
}