.page {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(50, 153, 255);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.img {
  background-image: url(../../assets/pay_error_desktop.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 70%;
  aspect-ratio: 1080 / 800;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .img {
    background-image: url(../../assets/pay_error_mobile.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 90%;
    aspect-ratio: 1080 / 800;
    cursor: pointer;
  }
}
