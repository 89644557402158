.content {
  display: flex;
  justify-content: center;
}

.form {
  box-sizing: border-box;
  flex-basis: 450px;
  padding-top: 100px;
  padding-left: 33px;
  padding-right: 33px;
  padding-bottom: 10px;
}

.form_title {
  font-size: 13px;
  line-height: 30px;
  color: #666666;
  margin-bottom: 6px;
}

.form_subtitle {
  font-size: 12px;
  line-height: 30px;
  color: #666666;
  margin-bottom: 6px;
}

.topic {
  color: #333333;
  font-size: 30px;
  line-height: 42px;
  text-align: center;
  position: relative;
}

.topic::after {
  content: '';
  width: 30px;
  height: 3px;
  background-color: #cccccc;
  position: absolute;
  bottom: -3px;
  left: 50%;
  transform: translateX(-50%);
}

.row {
  display: flex;
  justify-content: space-between;
}

.error {
  color: #ee5c6e;
}

.error_message {
  font-size: 12px;
}

.error_bottom {
  border-bottom: 1px solid #ee5c6e;
}

select.error_border, div.error_border {
  border: 1px solid #ee5c6e;
}

.submit {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}
