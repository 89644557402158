.swiper-container-horizontal>.custom-pagination-bullets{
  bottom: 110px;
  left: 0;
  width: 100%;
}

.swiper-container-horizontal>.custom-pagination-bullets .swiper-pagination-bullet {
  margin: 0 4px;
}

.swiper-button-disabled {
  display: none;
}