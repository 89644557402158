.sidebar_layout {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 26px;
  margin-top: 20px;
}

.info_layout {
  margin-bottom: 16px;
}

.door,
.total_price {
  font-size: 14px;
  color:#505050;
}

.total_price {
  display: flex;
  justify-content: space-between;
}

.gap {
  margin: 16px 0px;
  width: 100%;
  height: 1px;
  background-color: #d4d4d4;
}

.qrcode_layout {
  text-align: center;
  margin-bottom: 20px;
}

.copy_link_layout {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.copy_link {
  flex-basis: 150px;
  height: 30px;
  border-radius: 5px;
}

.description {
  font-size: 16px;
  color: #282828;
  margin-bottom: 10px;
  line-height: 30px;
}

.center_info {
  font-size: 12px;
  color: #666666;
  margin-top: 22px;
  margin-bottom: 10px;
  text-align: center;
}

.arrow_img {
  width: 50px;
  position: absolute;
  right: 40px;
}

.direct_layout {
  display: flex;
  justify-content: center;
}

.link_btn {
  background-color: #416EDE;
  position: relative;
  flex-basis: 450px;
}

@media screen and (max-width: 768px) {
  .link_btn {
    width: 100%;
    flex-basis: auto;
  }
}